import Catalogue from '@/views/_catalogue/index.vue';
import Cgv from '@/views/_cgv/index.vue';

import ErrorNotFound from '@/views/errors/error-404.vue';
import AuthViewRedirector from '@/views/redirector.vue';
// import AuthViewConnexion from '@/views/connexion.vue';

const navigation_tree = [
    {
        path: '/',
        name: 'catalogue',
        meta: {
            no_container_wrapper: true,
            show_header: true
        },
        props: route => ({query: route.query}),
        component: Catalogue
    },
    {
        path: '/conditions',
        name: 'cgv',
        meta: {
            no_container_wrapper: true,
            show_header: true
        },
        component: Cgv
    },
    {
        path: '/fiche/:fiche_id',
        name: 'fiche',
        props: true,
        component: () => import('@/views/_catalogue/fiche.vue'),
        meta: {
            no_container_wrapper: true,
            show_header: true
        },
    },
    {
        path: '/fiche/preview/:preview_id',
        name: 'preview',
        props: true,
        component: () => import('@/views/_catalogue/fiche.vue'),
        meta: {
            no_container_wrapper: true
        },
    },
    {
        path: '/connexion',
        name: 'connexion',
        meta: {
            label: 'Connexion',
            hide_in_navmenu: true,
        },
        beforeEnter(to, from, next) {
            location.href = `${process.env.VUE_APP_API_AUTH}/login?to=${process.env.VUE_APP_AUTH_REDIRECT}`;
            next();
        },
        // component: AuthViewConnexion
    },
    {
        path: '*',
        name: 'error-404',
        meta: {
            show_header: true,
        },
        component: ErrorNotFound,
    },
    {
        path: '/redirector',
        name: 'redirector',
        meta: {
            show_header: true,
            no_container_wrapper: true
        },
        component: AuthViewRedirector,
    }
];

export default {
    navigation_tree
};
