<template>
  <div v-if="list && list.length > 0"
       class="pl-3 pr-2 search__item"
       v-bind:class="`${width}`">

    <div class="mb-3 text-primary-900 font-bold" v-if="label">
      {{label}}
    </div>

    <div
      v-for="item in list"
      v-bind:key="item.id"
      class="w-full pl-1 pb-2 truncate">
      <label
        v-bind:key="item.id"
        v-tooltip="{content: item.label, placement:'left'}"
        class="cursor-pointer flex text-core-600 hover:text-core-900 my-1"
        v-bind:class="{'text-core-900': item.checked}"
      >
        <input-checkbox class="input-checkbox" v-model="item.checked"/>
        <span class="pl-2 truncate">{{item.label}}</span>
      </label>
    </div>

  </div>
</template>

<script>
import inputCheckbox from '@/components/utils/input-checkbox.vue';
export default {
    components: {
        inputCheckbox
    },
    props: ['list', 'label', 'width'],
};
</script>

<style lang="scss" scoped>
  .search__item {
    &:not(:first-child){
      @apply border-l border-core-400;
    }
    label:hover {
      /deep/ .checkbox-container input:not(:checked) ~ span:before {
        @apply bg-core-400;
      }
    }
}
</style>
