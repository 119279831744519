
export default {
    methods: {
        formatParam(key, value) {
            if (value == null || !value) {
                return '';
            }
            if (typeof value == 'string' || typeof value == 'number') {
                return value;
            }
            if (Array.isArray(value)) {
                return `${value.join(',')}`;
            }
        },
        formatParams(params) {
            let newParams = {...params};
            Object.keys(params).forEach(key => {
                const value = params[key];
                if (value && (key === 'dateDebut' || key === 'dateFin')) {
                    newParams = {...newParams, [key] : value.toISOString().split('T')[0]};
                } else if (value && value.length > 0) {
                    newParams = {...newParams, [key] : this.formatParam(key, params[key])};
                } else {
                    delete newParams[key];
                }
            });
            return newParams;
        }
    }
};
