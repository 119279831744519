<template>
  <div>
    <header class="header" v-click-outside="closeSideBar">
      <!-- Header's top section -->
      <div class="header__inner header__inner--start">

        <div v-on:click="toogleSideBar"
             class="header__wrap header__wrap--top-left">
          <div class="header__item">
            <button class="menu-btn">
              <font-awesome-icon v-bind:icon="sidebar ? 'times' : 'bars'"
                                 size="2x"
                                 class="align-middle"/>
            </button>
          </div>
        </div>
        <div class="header__wrap header__wrap--top-center">
          <div class="header__item py-3 px-3 lg:px-12">
            <header-logo/>
          </div>
        </div>
        <div class="header__wrap header__wrap--top-right">
          <div class="header__item">
            <header-account-button-cncc class="header__link"/>
          </div>
        </div>
      </div>

      <!-- Header's bottom section -->
      <div class="header__inner header__inner--end">
        <header-navbar/>
        <header-sidebar v-if="sidebar"
                        v-on:close="closeSideBar"
                        class="header-navbar--alt"/>
      </div>
    </header>
  </div>
</template>

<script>
import headerSidebar from '@/components/header/header-sidebar.vue';
import headerLogo from '@/components/header/header-logo.vue';
import headerAccountButtonCncc from '@/components/header/header-account-button-cncc.vue';
import headerNavbar from '@/components/header/header-navbar.vue';

import vClickOutside from 'v-click-outside';

import {createNamespacedHelpers} from 'vuex';
const {mapState: mapStateObserver, mapMutations: mapMutationsObserver} = createNamespacedHelpers('observer');

export default {
    components: {
        headerAccountButtonCncc,
        headerSidebar,
        headerLogo,
        headerNavbar
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    computed: {
        ...mapStateObserver({
            scrolled: (state) => state.scrolled,
            sidebar: (state) => state.sidebar
        })
    },
    watch: {
        $route(to, from) {
            this.closeSideBar();
        }
    },
    beforeDestroy() {
        this.closeSideBar();
    },
    methods: {
        ...mapMutationsObserver({
            setSidebar: 'setSidebar'
        }),
        toogleSideBar() {
            this.setSidebar(!this.sidebar);
        },
        closeSideBar() {
            this.setSidebar(false);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/utils/mixins.scss';

.header {
    @apply flex flex-wrap w-full shadow-lg;

    .header__inner {
        @apply relative flex h-full flex-wrap w-full items-center;

        &.header__inner--start {
            @apply h-20 bg-primary-900 text-core-100 justify-between items-start z-10;
        }

        &.header__inner--end {
            @apply flex justify-center;
        }
    }

    .header__wrap {
        @apply h-full flex;

        &.header__wrap--top-left,
        &.header__wrap--top-right {
            @apply z-50;
        }
        &.header__wrap--top-center {
          @apply flex-1 items-center justify-center bg-primary-900;
          // background-image: url(https://portail-irf.cfpc.net/img/bandeaux/bandeau-076.jpg);
          // background-repeat: no-repeat;
          // background-size: cover;
          // background-position: center 20%;
          //
          // &:before, &:after {
          //   content: '';
          //   @apply absolute top-0 bottom-0 w-1/2 transform -skew-x-30;
          // }
          //
          // &:before {
          //   @apply left-0 bg-gradient-to-r via-white from-white;
          // }
          // &:after {
          //   @apply right-0 bg-gradient-to-l via-white from-white;
          // }
        }

        .header__item {
            @apply h-full flex flex-wrap items-center;

            .menu-btn {
                @apply w-20;

                &:focus {
                    outline: none;
                    border: none;
                }
            }

            .header__link {
                @apply w-full h-full inline-flex items-center text-xs bg-transparent;
                @include text-link();
            }
        }

    }
}
</style>
