<template>
  <div class="simplebar-scroll">
    <transition name="fade">
      <font-awesome-icon v-if="scrolled"
                         v-on:click="scrollTop"
                         class="simplebar-scroll__icon"
                         v-bind:class="colors ? colors : 'bg-accent-700'"
                         icon="chevron-up"/>
    </transition>
  </div>
</template>

<script>
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.min.css';

export default {
    props: {
        target: {

        },
        colors: {
            type: String
        }
    },
    data() {
        return {
            scrolled: false,
            simplebar: null,
            scroll_spy: null
        };
    },
    mounted() {
        const current_target = this.$parent.$refs[this.target];

        this.simplebar = new SimpleBar(current_target);
        this.scroll_spy = this.simplebar.getScrollElement();

        this.scroll_spy.addEventListener('scroll', this.onScroll);
    },
    beforeDestroy() {
        this.scroll_spy.removeEventListener('scroll', this.onScroll);
        SimpleBar.removeObserver();
    },
    methods: {
        onScroll(event) {
            this.scrolled = event.target.scrollTop > 10;
        },
        scrollTop() {
            const scroller = this.simplebar.getScrollElement();
            scroller.scroll({
                top: 0,
                behavior: 'smooth'
            });
        },
    }
};
</script>

<style lang="scss" scoped>
.simplebar-scroll {
    @apply z-10 absolute w-10 h-10 right-0 bottom-0 mr-4 mb-3;

    .simplebar-scroll__icon {
        @apply cursor-pointer flex flex-1 w-full h-full p-3 rounded-full opacity-25 transition ease-in-out duration-200 text-white shadow;

        &:hover {
            @apply opacity-75;
        }
    }
}
.svg-inline--fa {
    @apply w-full;
}
</style>
