<template>
  <div class="conditions">
    <template v-if="!loading">
      <div v-on:click="triggerRedirect"
           class="btn-back__link">
        <font-awesome-icon class="link__icon"
                           icon="chevron-circle-left"
                           size="1x"/>
        <span class="link__text">Retourner à la page précédente</span>
      </div>
      <section class="conditions__content"
               v-bind:class="{'conditions__content--is-cncc': is_cncc}"
               v-html="formatCgv"></section>
    </template>
    <template v-else>
      <spinner
        sizes="w-20 h-20"
        thickness="border-8"/>
    </template>

  </div>

</template>

<script>
import {API} from '@/http-common';
import spinner from '@/components/utils/spinner.vue';

import {createNamespacedHelpers} from 'vuex';
const {mapState: mapStateAuth} = createNamespacedHelpers('auth');

export default {
    components: {
        spinner,
    },
    data() {
        return {
            cgv:  null,
            loading: false,
        };
    },
    computed: {
        ...mapStateAuth({
            redirect_route_path: (state) => state.redirect_route_path,
        }),
        is_cncc() {
            return process.env.VUE_APP_PRIMARY_COLOR === 'cncc';
        },
        formatCgv() {
            return this.cgv && this.cgv.length ? this.cgv : '<h1>Aucune CGV disponible</h1>';
        }
    },
    async created() {
        await this.fetchPerimetre();
    },
    methods: {
        async fetchPerimetre() {
            this.loading = true;
            const request = 'catalogue/perimetre';
            const response = await API.get(request);
            if (response && response.data) {
                this.cgv = response.data.cgvDescription;
            }
            this.loading = false;
        },
        triggerRedirect() {
            const redirect_path = this.redirect_route_path && this.redirect_route_path !== '/conditions' ? this.redirect_route_path : '/';
            this.$router.push(redirect_path);
        }
    },
};
</script>

<style lang="scss" scoped>
.conditions {
  @apply w-full h-full flex flex-wrap container mx-auto;

  .conditions__content {
    @apply min-w-full min-h-full;
  }

  .btn-back__link {
    @apply cursor-pointer flex items-center justify-center bg-white rounded-lg uppercase font-bold text-sm leading-none shadow-sm hover:text-primary-800 focus:text-primary-800 hover:shadow transition duration-150 ease-out pt-1.5 pb-1 px-3 mt-10;

    .link__icon {}
    .link__text {
        @apply ml-2;
    }
  }

  /deep/ section.conditions__content {
    &.conditions__content--is-cncc {
      @apply bg-core-100 pl-8 pr-12 pb-8 mt-4;
    }
    @apply leading-snug text-justify;

    h1 {
      @apply text-primary-900 mt-12 mb-8;
    }

    h2 {
      @apply text-primary-900 mt-8 mb-4;
    }

    h3 {
      @apply text-primary-900 mt-6 mb-2;
    }

    h4 {
      @apply text-primary-900 mt-4 mb-2;
    }

    h5 {
      @apply text-primary-900 mt-4 mb-2;
    }

    h6 {
      @apply text-primary-900 mt-4 mb-2;
    }
    p {
      @apply my-3;
    }
  }
}
</style>
