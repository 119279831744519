<template>
  <header class="header">

    <!-- Header's top section -->
    <div class="header__inner header__inner--start">

      <div class="header__wrap header__wrap--top-left">
        <div class="header__item">
          <header-home-button/>
        </div>
      </div>
      <div class="header__wrap header__wrap--top-center">
        <div class="header__item z-0 py-1">
          <header-logo/>
        </div>
      </div>
      <div class="header__wrap header__wrap--top-right">
        <div class="header__item">
          <header-account-button class="header__link"/>
        </div>
      </div>

    </div>

  </header>
</template>

<script>
import headerAccountButton from '@/components/header/header-account-button.vue';
import headerLogo from '@/components/header/header-logo.vue';
import headerHomeButton from '@/components/utils/home-button.vue';

import {createNamespacedHelpers} from 'vuex';
const {mapState: mapStateObserver} = createNamespacedHelpers('observer');

export default {
    components: {
        headerHomeButton,
        headerAccountButton,
        headerLogo
    },
    computed: {
        ...mapStateObserver({
            scrolled: (state) => state.scrolled,
        }),
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/utils/mixins.scss';

.header {
    @apply flex flex-wrap w-full;

    .header__inner {
        @apply inline-flex h-full flex-wrap w-full items-center;

        &.header__inner--start {
            @apply h-20 bg-white text-core-700 justify-between z-10;
        }
    }

    .header__wrap {
        @apply h-full inline-flex;

        &.header__wrap--top-left,
        &.header__wrap--top-right {
            @apply z-50;
        }
        &.header__wrap--top-center {
          @apply flex-1 items-center justify-center;
        }

        .header__item {
            @apply h-full flex flex-wrap items-center;

            .header__link {
                @apply w-full h-full inline-flex items-center text-xs;
                @include text-link();
            }
        }

    }
}
</style>
