
<template>
  <div class="catalogue-list">
    <div v-if="loading"
         class="w-full h-full flex items-center justify-center">
      <spinner v-bind:class="`${is_cncc ? 'text-white' : ''}`"
               sizes="w-20 h-20"
               thickness="border-8"/>
    </div>

    <template v-else-if="!loading && fiches">
      <div class="catalogue-list__inner">
        <div class="catalogue-list__inner__head">
          <p class="head__results"
             v-bind:class="`${is_cncc ? 'text-white' : 'text-accent-600'}`">
            {{fiches.length}} Résultat{{fiches.length > 1 ? 's' :''}} disponible{{fiches.length > 1 ? 's': ''}}
          </p>
          <div v-if="isActivatedCategories"
               v-on:click.stop="resetAllFilters"
               class="head__clear-all">
            <font-awesome-icon icon="times-circle" class="clear-all__icon"/>
            <span class="clear-all__text">
              Retirer tous les filtres
            </span>
          </div>

        </div>
        <div class="catalogue-list__inner__content">
          <display-fiches-catalogue v-bind:fiches="fiches"/>
        </div>
      </div>
    </template>
    <spinner v-else
             sizes="w-20 h-20"
             thickness="border-8"/>

  </div>
</template>

<script>
import spinner from '@/components/utils/spinner.vue';
import displayFichesCatalogue from '@/components/utils/display-fiches-catalogue.vue';

import {
    createNamespacedHelpers
} from 'vuex';
import {ACTION_TYPES} from '@/store/constants/ActionTypes';
import {filterInitialState} from '@/store/modules/catalogue';
const {
    mapState: mapStateCatalogue,
    mapActions: mapActionsCatalogue
} = createNamespacedHelpers('catalogue');
export default {
    components: {
        spinner,
        displayFichesCatalogue
    },
    props: {
        fiches: {
            type: Array
        },
        loading: {
            type:Boolean,
            default: true
        }
    },
    data() {
        return {
            no_output: 'Aucun contenu disponible...',
        };
    },
    computed: {
        ...mapStateCatalogue(['filters']),
        isActivatedCategories() {
            return JSON.stringify(this.filters) !== JSON.stringify(filterInitialState);
        },
        is_cncc() {
            return process.env.VUE_APP_PRIMARY_COLOR === 'cncc';
        }
    },
    methods: {
        ...mapActionsCatalogue([ACTION_TYPES.RESET_ALL_FILTERS]),
    }
};
</script>

<style lang="scss" scoped>
.catalogue-list {
    @apply flex w-full h-full;

    .catalogue-list__inner {
        @apply w-full h-full py-4 pl-6 pr-12;

        .catalogue-list__inner__head {
            @apply flex justify-between;

            .head__results {
                @apply font-bold;
            }

            .head__clear-all {
                @apply cursor-pointer inline-flex bg-danger-600 text-white py-1 px-3 rounded shadow items-center;

                &:hover {
                    @apply bg-danger-700 text-white;
                }

                .clear-all__icon {}
                .clear-all__text {
                    @apply ml-2;
                }
            }
        }

        .catalogue-list__inner__content {}
    }

    .catalogue-list__no-output {
        @apply text-xl text-core-700;
    }
}
</style>
