<template>
  <div v-if="!isTextEditorContentEmpty"
       class="richtext-block"
       v-bind:class="[
         {'richtext-block--preview': preview},
         {'richtext-block--left-border': border_left},
         {'richtext-block--left-indent': left_indent}
       ]">

    <label v-if="label" class="richtext-block__label">
      {{label}}
    </label>

    <div v-html="format_source" class="richtext-block__content"></div>

  </div>
</template>

<script>
import helpers_filters from '@/mixin/helpers_filters';
export default {
    mixins: [helpers_filters],
    props: {
        read_more_limit: {
            type: Number
        },
        preview: {
            type: Boolean,
            default: false
        },
        left_indent: {
            type: Boolean,
            default: false
        },
        border_left: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: null
        },
        source: {
            type: String,
        }
    },
    data() {
        return {};
    },
    computed: {
        isTextEditorContentEmpty() {
            // console.log(this.label === 'Préambule' && this.source);
            const regex = /(\s*)<\s*p[^>]*>(\s*)(&nbsp;(\s*))*(\s*)\u200b?<br>(\s*)<\s*\/\s*p>(\s*)/g;
            const match = this.source && this.source.match(regex);
            return match;
        },
        format_source() {
            if (this.read_more_limit) {
                return this.$options.filters.readMore(this.source, this.read_more_limit, '...');
            } else {
                return this.source;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.richtext-block {
    @apply break-words;

    &.richtext-block--preview {
        /deep/ img {
            display: none;
        }
    }
    &.richtext-block--left-border {
      @apply pl-3 ml-1 my-3 border-core-400 border-l;
    }
    &.richtext-block--left-indent {
      @apply pl-2 my-2;
    }

    .richtext-block__label {
        @apply block uppercase tracking-wide text-core-700 text-sm font-bold my-2;
    }
    .richtext-block__content {
      /deep/ a {

        @apply text-primary-800 hover:text-primary-900 hover:underline;
      }
    }
}
</style>
