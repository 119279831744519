<template>
  <form class="catalogue-search-form"
        v-bind:class="`${is_cncc ? '' : 'mx-4 my-3 rounded-lg'}`">
    <div class="catalogue-search-form__wrapper">

      <div class="catalogue-search-form__item">
        <input-box v-model="mot_cles"
                   label="Recherche par mot(s) clé(s)"
                   label_icon="search"
                   searchbox
                   v-on:input="resetSearchMotClesOnInput"
                   v-on:click-search="triggerMotClesSearch(mot_cles)"
                   v-on:clear-search="resetSearchOnMotCles(mot_cles)"/>
      </div>

      <div class="catalogue-search-form__item">

        <datepicker-box v-model="date_start"
                        v-on:turn-off="setFilter({name: FILTERS.dateDebut.name, value: null})"
                        v-on:turn-on="setFilter({name: FILTERS.dateDebut.name, value: date_start})"
                        label="Date de début"
                        v-bind:is_active="!!filters.dateDebut"
                        v-bind:label_icon="['far', 'calendar-alt']"
                        v-on:input="updateDateValue(date_start,FILTERS.dateDebut.name)"/>
      </div>

      <div class="catalogue-search-form__item">

        <datepicker-box v-model="date_end"
                        v-on:turn-off="setFilter({name: FILTERS.dateFin.name, value: null})"
                        v-on:turn-on="setFilter({name: FILTERS.dateFin.name, value: date_end})"
                        label="Date de fin"
                        v-bind:compare_date="filters.dateDebut"
                        v-bind:is_active="!!filters.dateFin"
                        v-bind:label_icon="['far', 'calendar-alt']"
                        v-on:input="updateDateValue(date_end,FILTERS.dateFin.name)"/>
      </div>

      <div class="catalogue-search-form__item">
        <select-box v-bind:value="selected_villes"
                    v-on:input="updateVillesValue"
                    v-bind:categories="villes"
                    label="Ville(s)"
                    label_icon="map-marker-alt"
                    multiple
                    message_key="city"
                    tracking="id"/>
      </div>

      <div class="catalogue-search-form__item">
        <div v-on:click="triggerAdvancedSearchModal"
             class="flex cursor-pointer rounded  w-full h-12 shadow items-center justify-center transition duration-300 ease-in-out p-3"
             v-bind:class="`${is_cncc ? 'text-white bg-core-900 hover:bg-secondary-600' : 'border border-primary-900 text-core-100 hover:bg-primary-800 bg-primary-900'}`">
          <span>Recherche Avancée</span>
        </div>
      </div>
    </div>

    <modal-box v-if="modal_advanced_search"
               v-on:open="initAdvancedSearch"
               v-on:close="onAdvancedModalClose"
               overflow_body
               v-bind:width_class="number_of_filters > 1 ? `w-${number_of_filters}/6`: 'w-1/3'"
               v-bind:footer="true">

      <template #modal-header>
        <h1 class="text-xl">Critères</h1>
      </template>

      <template #modal-body>
        <div v-if="number_of_filters > 0" class="flex flex-wrap mt-4 mb-12">
          <advanced-search-list
            v-bind:list="certifiant_list"
            v-bind:label="'CERTIFIANT'"
            v-bind:width="`w-1/${number_of_filters}`"
          />
          <advanced-search-list
            v-bind:list="famille_produits_list"
            v-bind:label="'FAMILLE DE PRODUITS'"
            v-bind:width="`w-1/${number_of_filters}`"
          />
          <advanced-search-list
            v-bind:list="publics_list"
            v-bind:label="'PUBLIC CONCERNE'"
            v-bind:width="`w-1/${number_of_filters}`"
          />
          <advanced-search-list
            v-bind:list="tags_list"
            v-bind:label="'TAGS'"
            v-bind:width="`w-1/${number_of_filters}`"
          />
          <advanced-search-list
            v-bind:list="collections_list"
            v-bind:label="'COLLECTION'"
            v-bind:width="`w-1/${number_of_filters}`"
          />
        </div>
        <div v-else class="my-4 italic font-bold">
          Aucun critère de recherche avancée disponible...
        </div>
      </template>
      <template #modal-footer>
        <div class="w-full inline-flex space-x-4 justify-end">
          <div v-on:click="onAdvancedModalClose()" class="cursor-pointer w-64 bg-core-200 py-3 px-5 hover:bg-core-300 text-core-700 font-bold rounded shadow text-center">
            Fermer
          </div>
          <div v-if="number_of_filters > 0"
               v-on:click="validateAdvancedSearch()"
               class="cursor-pointer w-64 bg-primary-900 py-3 px-5 hover:bg-primary-800 text-white font-bold rounded shadow text-center">
            Valider
          </div>
        </div>
      </template>

    </modal-box>

  </form>
</template>

<script>
import {API} from '@/http-common';

import {format} from 'date-fns';

import {createNamespacedHelpers} from 'vuex';
const {
    mapState: mapStateCatalogue,
    mapActions: mapActionsCatalogue
} = createNamespacedHelpers('catalogue');
import {FILTERS} from '@/assets/data/data_maps';
import advancedSearchList from '@/components/utils/advanced-search-list';
import modalBox from '@/components/utils/modal-box.vue';
import selectBox from '@/components/utils/select-box.vue';
import inputBox from '@/components/utils/input-box.vue';
import datepickerBox from '@/components/utils/datepicker-box.vue';
import {ACTION_TYPES} from '@/store/constants/ActionTypes';

export default {
    components: {
        selectBox,
        inputBox,
        datepickerBox,
        modalBox,
        advancedSearchList
    },
    props: ['villes', 'famille_produits', 'is_catalogue_empty'],
    data() {
        const filters = this.$store.state.catalogue.filters;
        return {
            date_start: filters.dateDebut ? filters.dateDebut : null,
            date_end: filters.dateFin ? filters.dateFin : null,
            mot_cles: filters.motClesQuery ? decodeURI(filters.motClesQuery) : '',
            modal_advanced_search: false,
            selected_villes: [],
            tags_list: [],
            collections_list: [],
            publics_list: [],
            certifiant_list: [],
            famille_produits_list: []
        };
    },
    computed: {
        ...mapStateCatalogue(['filters']),
        FILTERS() {
            return FILTERS;
        },
        number_of_filters() {
            const filters = [this.collections_list && this.collections_list.length > 0
                , this.tags_list && this.tags_list.length > 0
                , this.publics_list && this.publics_list.length > 0, this.famille_produits_list && this.famille_produits_list.length > 0, this.certifiant_list && this.certifiant_list.length > 0].filter(item => item);

            const reduce_lists = () => filters.reduce((a, b) => a + b);

            return 0 + (filters.length > 0 ? reduce_lists() : 0);
        },
        is_cncc() {
            return process.env.VUE_APP_PRIMARY_COLOR === 'cncc';
        }
    },
    watch: {
        is_catalogue_empty: {
            immediate: true,
            handler(newVal) {
                this.certifiant_list = !newVal ? [{
                    id: 'true',
                    label: 'Oui'
                }, {
                    id: 'false',
                    label: 'Non'
                }] : [];
            }
        },
        villes(newVal) {
            this.fetchStoredVilles();
        },
        [`filters.${FILTERS.motClesQuery.name}`](newVal) {
            if (!newVal) {
                this.mot_cles = null;
            }
        },
        [`filters.${FILTERS.dateDebut.name}`](newVal) {
            if (!newVal) {
                this.date_start = null;
            }
        },
        [`filters.${FILTERS.dateFin.name}`](newVal) {
            if (!newVal) {
                this.date_end = null;
            }
        },
        [`filters.${FILTERS.sessionLieuIds.name}`](newVal) {
            if (!newVal.length) {
                this.selected_villes = [];
            }
        },
    },
    async created() {
        this.fetchStoredVilles();
        this.fetchFilters();
        this.initAdvancedSearch();
        window.addEventListener('keyup', this.handleEnterKey);
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleEnterKey);
    },
    methods: {
        ...mapActionsCatalogue(
            [ACTION_TYPES.SET_FILTER, ACTION_TYPES.SET_FILTERS]
        ),
        async fetchFilters() {
            await API.get('catalogue/tags')
                .then((response)=> {
                    this.tags_list = response.data;
                });
            await API.get('catalogue/collections')
                .then((response)=> {
                    this.collections_list = response.data;
                });
            await API.get('catalogue/public-concernes')
                .then((response)=> {
                    this.publics_list = response.data && response.data.sort((a, b) => a.ordre - b.ordre);
                });

            this.famille_produits_list = this.famille_produits && this.famille_produits[0] && this.famille_produits[0].datas;
        },
        fetchStoredVilles() {
            if (this.filters.sessionLieuIds) {
                const current_villes = this.filters.sessionLieuIds.map((id) => {
                    const ville = this.villes.filter((ville) => ville.id == id);
                    return ville[0];
                });

                this.selected_villes = current_villes;
            }
        },
        triggerAdvancedSearchModal() {
            this.modal_advanced_search = true;
        },
        updateDateValue(data, name) {
            const date = format(data, 'yyyy-MM-dd');
            const payload = new Date(date);
            this.setFilter({name, value: payload});
        },
        updateVillesValue(data) {
            const payload = data.map((val) => {
                return val.id;
            });
            this.setFilter({name: FILTERS.sessionLieuIds.name, value: payload});
        },
        resetSearchOnMotCles() {
            this.mot_cles = null;
            this.setFilter({name: FILTERS.motClesQuery.name, value: null});
        },
        resetSearchMotClesOnInput(data) {
            if (data.length < 1) {
                this.setFilter({name: FILTERS.motClesQuery.name, value: null});
            }
        },
        triggerMotClesSearch(data) {
            if (data) {
                this.setFilter({name: FILTERS.motClesQuery.name, value: encodeURI(data)});
            }
        },
        onAdvancedModalClose() {
            this.modal_advanced_search = false;
        },
        validateAdvancedSearch() {
            const tagsIds = this.tags_list && this.tags_list.filter(item => item.checked).map(item => item.id);
            const collectionsIds = this.collections_list && this.collections_list.filter(item => item.checked).map(item => item.id);
            const publicConcernesIds = this.publics_list && this.publics_list.filter(item => item.checked).map(item => item.id);
            const certifiantListFiltered = this.certifiant_list.filter(item => item.checked);
            const certifiant = certifiantListFiltered && certifiantListFiltered.length === 1 ? this.certifiant_list.filter(item => item.checked)[0].id : null;
            const familleProduitsListFiltered = this.famille_produits_list && this.famille_produits_list.filter(item => item.checked);
            const familleFormationContinue = familleProduitsListFiltered.some(item => item.booleanAttribute === 'familleFormationContinue') ? [true] : null;
            const familleStage = familleProduitsListFiltered.some(item => item.booleanAttribute === 'familleStage') ? [true] : null;

            this.setFilters({...this.filters, tagsIds, collectionsIds, publicConcernesIds, certifiant, familleFormationContinue, familleStage});
            this.$nextTick(()=> {
                this.onAdvancedModalClose();
            });
        },
        initAdvancedSearch() {
            this.tags_list = this.initFilter(this.tags_list, this.filters.tagsIds);
            this.collections_list = this.initFilter(this.collections_list, this.filters.collectionsIds);
            this.publics_list = this.initFilter(this.publics_list, this.filters.publicConcernesIds);
            this.certifiant_list = this.certifiant_list.map(certifiantItem => ({...certifiantItem, checked: certifiantItem.id == this.filters.certifiant}));
            this.famille_produits_list = this.famille_produits_list ? this.famille_produits_list.map(familleItem =>
                ({...familleItem, checked: this.filters[familleItem.booleanAttribute]})) : [];
        },
        initFilter(list, filterList) {
            return list.map(item => ({...item, checked: item.id && filterList.map(listItem => listItem.toString()).includes(item.id.toString())}));
        },
        handleEnterKey(e) {
            if (e.key === 'Enter') {
                this.validateAdvancedSearch();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.catalogue-search-form {
    @apply bg-accent-100 flex items-center justify-between shadow;

    .catalogue-search-form__wrapper {
        @apply w-full flex flex-wrap items-end p-4 justify-center;

        .catalogue-search-form__item {
            @apply inline-flex flex-grow w-1/5 px-2 py-2;
            /deep/ label {
                @apply text-primary-900;
            }
        }
    }
}
</style>
