<template>
  <div class="datepicker-box">
    <form-label v-bind:label="label"
                v-bind:icon="label_icon"
                class="datepicker-box__label"/>

    <div v-if="toggle_switch" class="items-center flex flex-wrap">
      <label v-tooltip="{
               content: !active_checkbox ? 'Activer' : 'Désactiver',
               placement: 'left',
               offset: 5
             }"
             class="switch">
        <input type="checkbox" v-model="active_checkbox"/>
        <span class="slider round"></span>
      </label>
    </div>

    <datepicker v-bind:language="fr"
                v-model="local_date"
                class="datepicker"
                v-bind:monday-first="true"
                required
                v-on:input="updateValue"
                input-class="datepicker-input"
                calendar-class="datepicker-calendar"
                v-bind:placeholder="placeholder"
                v-bind:format="customDateFormatter"
                v-bind:disabled-dates="disabledDates"
                v-bind:disabled="toggle_switch && !active_checkbox"/>
  </div>
</template>

<script>
import {fr} from 'vuejs-datepicker/dist/locale';
import frLocale from 'date-fns/locale/fr';
import {format} from 'date-fns';

import Datepicker from 'vuejs-datepicker';

import formLabel from '@/components/utils/form-label.vue';

const TODAY_DATE = new Date();
const RANGE_TODAY_DATE = new Date();
RANGE_TODAY_DATE.setDate(TODAY_DATE.getDate() - 1);

export default {
    components: {
        formLabel,
        Datepicker
    },
    props: {
        value: {
            required: true
        },
        label: {
            type: String,
            default: 'Date'
        },
        label_icon: {
            type: [String, Array]
        },
        compare_date: {
            type: Date
        },
        toggle_switch: {
            type: Boolean,
            default: false
        },
        is_active: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: 'Sélectionnez une date'
        }
    },
    data() {
        return {
            fr,
            local_date: this.value,
            active_checkbox: this.is_active
        };
    },
    computed: {
        disabledDates() {
            return {
                to : this.compare_date ? this.compare_date : RANGE_TODAY_DATE
            };
        }
    },
    watch: {
        is_active(newVal) {
            this.active_checkbox = newVal;
        },
        value(newVal) {
            this.local_date = newVal;
        },
        active_checkbox(newVal) {
            this.$emit(newVal ? 'turn-on' : 'turn-off');
        }
    },
    methods: {
        updateValue(e) {
            this.$emit('input', this.local_date);
        },
        customDateFormatter(date) {
            const formatedDate = format(date, 'EEEE d MMMM y', {
                locale: frLocale
            });
            return formatedDate.replace(/^\w/, (c) => c.toUpperCase());
        }
    }
};
</script>

<style lang="scss" scoped>
.datepicker-box {
    @apply flex flex-wrap w-full justify-between;

    .datepicker-box__label {
        @apply font-bold leading-none mb-2;
    }

    /deep/ .datepicker {
        @apply w-full text-core-700 border border-core-700 rounded items-center;

        .datepicker-input {
            @apply w-full disabled:text-accent-500 disabled:bg-accent-200 bg-white hover:text-core-900 cursor-pointer disabled:cursor-not-allowed py-3 px-4 rounded;
        }

        .datepicker-calendar {
            @apply w-full bg-white rounded-b-lg shadow;
            .cell {
                &:not(.selected):not(.blank):not(.disabled).day:hover,
                &:not(.selected):not(.blank):not(.disabled).month:hover,
                &:not(.selected):not(.blank):not(.disabled).year:hover {
                    @apply bg-core-100 border border-core-300;
                }

                &.selected {
                    @apply font-bold bg-core-200 border border-core-300;
                    &:hover {
                        @apply border border-core-500;
                    }
                }
            }
        }

        &:focus {
            @apply outline-none border-core-500;
        }
    }
}

/* The switch - the box around the slider */
.switch {
    @apply relative inline-flex items-center justify-center w-8 h-4 mb-2;
}

/* Hide default HTML checkbox */
.switch input {
    @apply opacity-0 w-0 h-0;
}

/* The slider */
.slider {
    @apply cursor-pointer absolute inset-0 bg-accent-500 transition duration-300 ease-in-out;
}

.slider:before {
    content: "";
    @apply absolute inset-0 bg-core-200 bg-white transition duration-300 ease-in-out w-3 h-3;
    left: 3px;
    top: 1px;
}

input:checked + .slider {
    @apply bg-primary-900;
}

input:checked + .slider:before {
    @apply transform translate-x-3;
}

/* Rounded sliders */
.slider.round {
    @apply rounded-lg;
}

.slider.round:before {
    @apply rounded-full;
}
</style>
