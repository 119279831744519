<template>
  <article>
    <div class="flex h-full items-center justify-center">
      <spinner/>
    </div>
  </article>
</template>

<script>
import {createNamespacedHelpers} from 'vuex';
const {mapState: mapStateAuth} = createNamespacedHelpers('auth');

import spinner from '@/components/utils/spinner.vue';

export default {
    components: {
        spinner
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.triggerRedirect();
        });
    },
    computed: {
        ...mapStateAuth({
            redirect_route_path: (state) => state.redirect_route_path,
        }),
    },
    methods: {
        triggerRedirect() {
            this.$router.push(this.redirect_route_path || '/');
        }
    }
};
</script>

<style lang="scss" scoped>

</style>
