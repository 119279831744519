// Official NUXT JS Toaster
// https://github.com/shakee93/vue-toasted

import Vue from 'vue';

import Toasted from 'vue-toasted';
const defaultOptions = {
    className: 'my-custom-toast',
    // 'my-custom-toast' overrides the 'outline' theme in App.vue styles
    theme: 'outline', // ['toasted-primary', 'outline', 'bubble']
    iconPack: 'custom-class', // Don't use 'fontawesome' option as svg-core doesn't match to this type of setup.
    position: 'bottom-center',
    // fullWidth: true,
    // fitToScreen: true,
    keepOnHover: true,
    singleton: true,
    duration: 3000,
    action : {
        text : 'Fermer',
        onClick : (e, toastObject) => {
            toastObject.goAway(0);
        }
    }
};

Vue.use(Toasted, {
    ...defaultOptions
});

Vue.toasted.register('default',
    // This payload function call should remain on top...
    (payload) => {
        if (!payload.message) {
            return 'Information... non disponible';
        }
        return payload.message;
    },
    // ...Then only we can add extra parameters
    {
        type: 'default',
        icon : {
            name: 'fas fa-share -mt-1 mr-4'
        },
    }
);

Vue.toasted.register('error',
    // This payload function call should remain on top...
    (payload) => {
        if (!payload.message) {
            return 'Oups.. Une erreur est survenue..';
        }
        return payload.message;
    },
    // ...Then only we can add extra parameters
    {
        type: 'error',
        icon : {
            name: 'fas fa-exclamation-triangle -mt-1 mr-4'
        }
    }
);

Vue.toasted.register('success',
    // This payload function call should remain on top...
    (payload) => {
        if (!payload.message) {
            return 'L\'opération s\'est correctement déroulée';
        }
        return payload.message;
    },
    // ...Then only we can add extra parameters
    {
        type: 'success',
        icon : {
            name: 'fas fa-check-circle -mt-1 mr-4'
        }
    }
);

Vue.toasted.register('info',
    // This payload function call should remain on top...
    (payload) => {
        if (!payload.message) {
            return 'Une étape mérite votre attention !';
        }
        return payload.message;
    },
    // ...Then only we can add extra parameters
    {
        type: 'info',
        icon : {
            name: 'fas fa-info-circle -mt-1 mr-4'
        }
    }
);
