// Official Fontawesome library for VueJS
// https://github.com/FortAwesome/vue-fontawesome

import Vue from 'vue';

import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome';

// import {library} from '@fortawesome/fontawesome-svg-core';
import {library, dom} from '@fortawesome/fontawesome-svg-core';

// DEVELOPEMENT (Load all icons)
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';

library.add(fab, fas, far);

// PRODUCTION (Pick up each necessary icons manually to reduce bundle size)
// import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
// import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
// import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
// import {faShare} from '@fortawesome/free-solid-svg-icons';

// library.add(faExclamationTriangle, faCheckCircle, faInfoCircle, faShare);

// Dynamically replacing <i> tags with <svg> !important in use with vue-toasted
dom.watch();

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('FontAwesomeLayers', FontAwesomeLayers);
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText);
