// (C) Copyright 2018 CNCC Services <https://www.cncc.fr/>
// This program is the sole property of CNCC Services and is not free software.

export default {
    filters: {
        minutesIntoHours(duration) {
            const convertedToHours = duration / 60;
            const hours = Math.trunc(convertedToHours);

            const remainder = Number(convertedToHours - hours);
            const minutes = (remainder * 60).toFixed();

            let result;
            if (minutes > 0) {
                if (minutes.length <= 1) {
                    result = `${hours}h${minutes}0`;
                } else {
                    result = `${hours}h${minutes}`;
                }
            } else {
                result = `${hours}h`;
            }

            return result;
        },
        displayJourString(value) {
            return value > 1 ? `${value} jours` : `${value} jour`;
        },
        firstLetterCapitalize(value) {
            if (!value) {
                return '';
            }
            // Turn value toString and trim all white spaces before the first letter
            value = value.toString().replace(/^\s\s*/, '');
            return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        },
        readMore(value, length, suffix) {
            return value.substring(0, length) + suffix;
        }
    }
};
