<template>
  <logo-image v-bind:src="fetchLogo"
              class="h-full"/>
</template>

<script>
import logoImage from '@/components/utils/logo-image.vue';

export default {
    components: {
        logoImage,
    },
    props: {
        alt: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        fetchLogo() {
            const logo = process.env.VUE_APP_LOGO;
            const logo_alt = process.env.VUE_APP_LOGO_ALT;
            return this.alt && logo_alt ? logo_alt : logo;
        }
    }
};
</script>
