import {AUTH} from '@/http-common';

const STORED_ROUTE_PATH = sessionStorage.getItem('redirect_route_path') ? sessionStorage.getItem('redirect_route_path') : null;

const state = {
    user: false,
    authenticated: false,
    redirect_route_path: STORED_ROUTE_PATH,
    checked_status: false
};

const mutations = {
    async setUser(state, userinfo) {
        state.user = userinfo;
    },
    setAuthenticationStatus(state) {
        if (!state.user) {
            state.authenticated = false;
        } else {
            state.authenticated = true;
        }
    },
    setRedirectUrlPath(state, route_path) {
        if (route_path) {
            state.redirect_route_path = route_path;
            sessionStorage.setItem('redirect_route_path', state.redirect_route_path);
        }
    },
    storeAuthTokens(state, tokens) {
        state.id_token = tokens.id_token;
        state.access_token = tokens.access_token;
    }
};

const actions = {
    async checAuth({commit}) {
        const fetched_user_info = await AUTH.get('/userinfo');
        await commit('setUser', fetched_user_info && fetched_user_info.data);
    },
    async applyAuthenticationStatus({dispatch, commit}) {
        await dispatch('checAuth');
        await commit('setAuthenticationStatus');
    }
};

// Define a getter in order to get your current username from your state
const getters = {
    username: (state) => {
        return state.user && state.user.name;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
