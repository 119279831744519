import axios from 'axios';
import router from './router';

const baseURLAuth = process.env.VUE_APP_API_AUTH;
const baseURLApi = process.env.VUE_APP_API_SAFIR;
/*=============================================>>>>>
= Init Axios calls to OIDC through SAFIR Front-server =
===============================================>>>>>*/
const AUTH = axios.create({
    baseURL: baseURLAuth,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    }
});

// Add a response interceptor
AUTH.interceptors.response.use(null, (error) => {
    if (error.response.status === 401) {
        return false;
    }

    // eslint-disable-next-line no-console
    console.error('Errors', error.response);

    return Promise.reject(error);
});

/*=============================================>>>>>
= Init Axios calls to SAFIR API =
===============================================>>>>>*/
const API = axios.create({
    baseURL: baseURLApi,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    }
});

// Add a response interceptor
API.interceptors.response.use(null, (error) => {
    if (error.response.status === 400) {
        return router.push({name:'error-404'});
    }

    // eslint-disable-next-line no-console
    console.error('Errors', error.response);

    return Promise.reject(error);
});

export {AUTH, API};
