var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.listSessions(_vm.sessions).length)?_c('div',[_c('p',{staticClass:"text-core-600 mr-1 w-full mb-1"},[_vm._v("\n    Prochaine(s) Session(s) :\n  ")]),_vm._v(" "),_c('ul',{staticClass:"pl-4"},[_vm._l((_vm.listSessions(_vm.sessions)),function(session,index){
var _obj, _obj$1;
return _c('li',{key:("session_" + index)},[(index < _vm.sessions_show_max)?[_c('span',{staticClass:"mr-2 font-bold",class:[
                {'text-danger-500': _vm.isActiveFilteredCity(session.name.id)},
                ( _obj = {}, _obj[_vm.type_text_color(5)] = session.name.classeVirtuelle, _obj )
              ]},[_vm._v("\n          "+_vm._s(session.name.nom)+" :\n        ")]),_vm._v(" "),_c('ul',{staticClass:"list-disc pl-6"},_vm._l((session.dates),function(date){return _c('li',{key:((session.id) + "_" + (date.id)),staticClass:" mb-1 text-primary-800 font-bold"},[_vm._v(_vm._s(date.label))])}),0)]:_vm._e(),_vm._v(" "),(_vm.sessions_show_max <= _vm.listSessions(_vm.sessions).length && index === _vm.sessions_show_max && !_vm.show_more)?[_c('div',{staticClass:"py-2 underline normal-case text-primary-800 hover:text-primary-900",class:{'text-danger-500': _vm.isActiveFilteredCity(session.name.id)},on:{"click":function($event){$event.preventDefault();return _vm.toggleCurrentList()}}},[_vm._v("\n          Voir plus de dates\n        ")])]:_vm._e(),_vm._v(" "),(index >= _vm.sessions_show_max && _vm.show_more)?[_c('span',{staticClass:"mr-2 font-bold",class:[
                {'text-danger-500': _vm.isActiveFilteredCity(session.name.id)},
                ( _obj$1 = {}, _obj$1[_vm.type_text_color(5)] = session.name.classeVirtuelle, _obj$1 )
              ]},[_vm._v("\n          "+_vm._s(session.name.nom)+" :\n        ")]),_vm._v(" "),_c('ul',{staticClass:"list-disc pl-6"},_vm._l((session.dates),function(date){return _c('li',{key:((session.id) + "_" + (date.id)),staticClass:" mb-1 text-primary-800 font-bold"},[_vm._v(_vm._s(date.label))])}),0)]:_vm._e()],2)}),_vm._v(" "),(_vm.show_more)?_c('li',[_c('div',{staticClass:"py-2 underline normal-case text-primary-800 hover:text-primary-900",on:{"click":function($event){$event.preventDefault();return _vm.toggleCurrentList()}}},[_vm._v("\n        Voir moins de dates\n      ")])]):_vm._e()],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }