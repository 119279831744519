<template>
  <transition name="modal">
    <!-- Modal Outside Box -->
    <div class="modal-mask">

      <!-- <div class="modal-wrapper"> -->
      <!-- Modal Inside Box -->
      <div class="modal-container"
           v-bind:class="[width_class, {'modal-container--min-full-height': fill_height}]"
           v-click-outside="closeModal">

        <div v-if="header" class="modal-header">
          <slot name="modal-header"></slot>
          <button class="modal-btn-close" v-on:click.prevent.stop="closeModal">
            <font-awesome-icon class="modal-btn-close__icon"
                               v-bind:icon="['far','times-circle']"
                               size="2x"/>
          </button>
        </div>

        <hr class="modal-hr"/>

        <div v-if="body"
             class="modal-body"
             v-bind:class="{'modal_body--overflow-y': overflow_body}">

          <slot name="modal-body"></slot>
        </div>

        <hr v-if="footer" class="modal-hr"/>

        <div v-if="footer" class="modal-footer">
          <slot name="modal-footer">

          </slot>
          <div v-if="copyright" class="w-full flex text-xs text-core-500">
            CNCC Formation {{new Date().getFullYear()}}
          </div>
        </div>

      </div>
    <!-- </div> -->

    </div>

  </transition>
</template>

<script>
import vClickOutside from 'v-click-outside';

export default {
    directives: {
        clickOutside: vClickOutside.directive
    },
    props: {
        header: {
            type: Boolean,
            default: true
        },
        body: {
            type: Boolean,
            default: true
        },
        footer: {
            type: Boolean,
            default: true
        },
        copyright: {
            type: Boolean,
            default: false
        },
        width_class: {
            type: String,
            default: 'w-11/12'
        },
        fill_height: {
            type: Boolean,
            default: false
        },
        overflow_body: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {};
    },
    created() {
        this.$emit('open');
        window.addEventListener('keyup', this.handleEscapeKey);
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleEscapeKey);
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        handleEscapeKey(e) {
            if (e.key === 'Escape') {
                this.closeModal();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/utils/mixins.scss';

.modal-mask {
    @apply flex items-center justify-center fixed z-50 inset-0 w-full h-full bg-black bg-opacity-75;
    transition: opacity 0.2s ease;
}

// .modal-wrapper {}
.modal-default-button {}

.modal-container {
    @apply flex flex-col mx-auto bg-white rounded-lg shadow-md;
    max-height: 90vh;

    @include inflate-transition();
    &.modal-container--min-full-height {
        height: 100%;
    }
}

.modal-body,
.modal-footer,
.modal-header {
    @apply py-4 px-6;
}

.modal-footer,
.modal-header {
    @apply w-full flex flex-wrap justify-between items-center;
}

.modal-hr {
    @apply border-core-300;
}

.modal-body {
    @apply py-4 flex-1;

    &.modal_body--overflow-y {
        @apply overflow-y-auto;
    }
}

.modal-header .modal-btn-close {
    .modal-btn-close__icon {
        @apply text-core-500 p-1;

        &:hover {
            @apply text-core-600;
        }
    }
}

/* Modal specific Vuejs animation on 'leave' w/ @include inflate-transition() */
.modal-leave-to {
    opacity: 0;
}
.modal-leave-active .modal-container {
    transform: scale(0.7);
}
</style>
