import Vue from 'vue';
import VueRouter from 'vue-router';

import navigation from '@/router/navigation_tree.js';

Vue.use(VueRouter);

const routes = [
    ...navigation.navigation_tree
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
