import {FILTERS} from '@/assets/data/data_maps';
import {ACTION_TYPES as ACTIONS_TYPES} from '@/store/constants/ActionTypes';

export const filterInitialState = {
    thematiquesIds: [],
    typesIds: [],
    metiersIds: [],
    sessionLieuIds: [],
    motClesQuery: null,
    dateDebut: null,
    dateFin: null,
    certifiant: null,
    familleFormationContinue: null,
    familleStage: null,
    publicConcernesIds: [],
    tagsIds: [],
    collectionsIds: []
};

const state = {
    filters: filterInitialState
};

const mutations = {
    async setFilters(state, payload) {
        state.filters = {...state.filters, ...payload};
    },
    async initFiltersFromQuery(state, payload) {
        state.filters = {...filterInitialState, ...parsePayload(payload)};
    },
    async setFilter(state, {name, value}) {
        state.filters = {...state.filters, [name]: value};
    },
    async resetAllFilters(state) {
        state.filters = filterInitialState;
    }
};

const parsePayload = (payload) => {
    const newPayload = {...payload};
    Object.keys(newPayload).forEach(key => {
        const filter = FILTERS[key];
        if (filter && filter.isArray) {
            newPayload[key] = newPayload[key].split(',');
        }
        if (key === FILTERS.dateDebut.name || key === FILTERS.dateFin.name) {
            newPayload[key] = new Date(newPayload[key]);
        }
    });
    return newPayload;
};

const actions = {
    initFiltersFromQuery({commit}, query) {
        commit(ACTIONS_TYPES.INIT_FILTERS_FROM_QUERY, query);
    },
    setFilters({commit}, payload) {
        commit(ACTIONS_TYPES.SET_FILTERS, payload);
    },
    setFilter({commit}, payload) {
        commit(ACTIONS_TYPES.SET_FILTER, payload);
    },
    resetAllFilters({commit}) {
        commit(ACTIONS_TYPES.RESET_ALL_FILTERS);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
