<template>
  <img class="logo-image"
       v-bind:class="[{'is-cncc': is_cncc}, extraclass ? `${extraclass}`: '']"
       v-bind:src="src"/>
</template>
<script>
export default {
    props: ['src', 'extraclass'],
    computed: {
        is_cncc() {
            return process.env.VUE_APP_PRIMARY_COLOR === 'cncc';
        }
    },
};
</script>
<style lang="scss" scoped>
  .logo-image {
    @apply h-full mx-auto bg-white;
    &.is-cncc{
      @apply h-auto max-h-full bg-transparent;
    }
  }
</style>
