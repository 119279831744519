<template>
  <section class="catalogue-thematiques"
           v-bind:class="{'is-cncc': is_cncc}">

    <div class="thematiques__open-close-all">
      <button v-on:click="closeAllOpener()"
              v-tooltip="'Tout replier'"
              class="btn-close-all">
        <font-awesome-icon size="sm" icon="angle-double-up"/>
      </button>

      <button v-on:click="expandAllOpener()"
              v-tooltip="'Tout déplier'"
              class="btn-open-all">
        <font-awesome-icon size="sm" icon="angle-double-down"/>
      </button>
    </div>

    <template v-for="category in categories" v-if="category.name !== 'famille_produits'">
      <div v-bind:key="category.name" class="thematique__wrapper">
        <label v-on:click="toggleOpener(category.name)"
               class="thematique__label"
               v-bind:class="{'thematique__label--active': !expand_status[category.name] && category.datas.length}">
          <div>
            <font-awesome-icon v-bind:icon="!expand_status[category.name] ? 'chevron-down': 'chevron-right'" class="label__icon"/>
            <span class="label__text">
              {{category.label}}
            </span>
          </div>
          <div v-if="isCategoryActive(category.name, category.datas)"
               v-on:click.stop="resetCategory(category.name, category.datas)"
               class="label__clear">
            Effacer tout
          </div>
        </label>

        <spinner v-if="loading && !expand_status[category.name]" v-bind:colors="`${is_cncc ? 'text-white' : 'text-core-500'}`"/>
        <div v-else-if="!category.datas.length && !expand_status[category.name]" class="thematique__content">
          <div class="item__wrapper">
            <div class="item item--empty">
              Aucun(e) {{category.label.toLowerCase()}} disponible
            </div>
          </div>
        </div>
        <div v-else
             class="thematique__content"
             v-bind:class="{'thematique__content--close': expand_status[category.name]}">

          <template v-for="item in category.datas">
            <!-- {{item}} -->
            <div v-if="item.count > 0"
                 v-bind:key="item.label"
                 class="item__wrapper">

              <div class="item"
                   v-bind:class="{'item--active': isItemActive(item.id, category.name, item.booleanAttribute)}"
                   v-on:click="!isItemActive(item.id, category.name, item.booleanAttribute) ? addValue(item.id, category.name, item.booleanAttribute): removeValue(item.id, category.name, item.booleanAttribute)">

                <div class="item__content item_content--icon">
                  <font-awesome-icon class="item__icon" icon="check"/>
                </div>

                <div class="item__content item_content--label">
                  <span class="item__text">{{item.label}}</span>
                  <span class="item__number" v-tooltip="{content: `<strong>${item.count} entrée(s)</strong> correspondantes pour cette thématique`}">
                    {{item.count}}
                  </span>
                </div>

              </div>

            </div>

          </template>
        </div>

      </div>
    </template>

  </section>
</template>

<script>
import spinner from '@/components/utils/spinner.vue';

import {
    createNamespacedHelpers
} from 'vuex';
import {
    map_categories_filters
} from '@/assets/data/data_maps';
import {
    ACTION_TYPES as ACTIONS_TYPES
} from '@/store/constants/ActionTypes';
const {
    mapState: mapStateCatalogue,
    mapActions: mapActionsCatalogue,
} = createNamespacedHelpers('catalogue');

export default {
    components: {
        spinner
    },
    props: {
        categories: {
            type: Array
        },
        loading: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            expand_status: {}
        };
    },
    computed: {
        ...mapStateCatalogue(['filters']),
        is_cncc() {
            return process.env.VUE_APP_PRIMARY_COLOR === 'cncc';
        }
    },
    methods: {
        ...mapActionsCatalogue(
            [ACTIONS_TYPES.SET_FILTER]
        ),
        toggleOpener(name) {
            if (this.expand_status[name]) {
                this.$set(this.expand_status, name, !this.expand_status[name]);
            } else {
                this.$set(this.expand_status, name, true);
            }
        },
        closeAllOpener() {
            this.categories.forEach((entry) => {
                this.$set(this.expand_status, entry.name, true);
            });
        },
        expandAllOpener() {
            this.expand_status = {};
        },
        isCategoryActive(name, datas) {
            if (name === 'famille_produits') {
                const attrs = datas.map((data) => data.booleanAttribute);
                return attrs.some((attr) => {
                    return this.filters[attr];
                });
            } else {
                const target = map_categories_filters[name];
                return target && this.filters[target] && this.filters[target].length > 0;
            }
        },
        resetCategory(name, datas) {
            if (name === 'famille_produits') {
                const attrs = datas.map((data) => data.booleanAttribute);
                attrs.forEach(item => {
                    this.setFilter({
                        name: item,
                        value: null
                    });
                });
            } else {
                const target = map_categories_filters[name];
                this.setFilter({
                    name: target,
                    value: []
                });
            }
        },
        isItemActive(id, type, booleanAttribute) {
            if (booleanAttribute) {
                const target = booleanAttribute;
                return this.filters[target];
            } else {
                const target = map_categories_filters[type];
                return target && this.filters[target] && this.filters[target].some((x) => x == id);
            }
        },
        addValue(id, type, booleanAttribute) {
            if (booleanAttribute) {
                this.setFilter({
                    name: booleanAttribute,
                    value: [true]
                });
            } else {
                const target = map_categories_filters[type];
                const ids = this.filters[target];
                if (ids && !ids.map(i => i.toString()).includes(id.toString())) {
                    this.setFilter({
                        name: target,
                        value: [...ids, id]
                    });
                }
            }
        },
        removeValue(id, type, booleanAttribute) {
            if (booleanAttribute) {
                this.setFilter({
                    name: booleanAttribute,
                    value: null
                });
            } else {
                const target = map_categories_filters[type];
                const ids = this.filters[target];
                if (ids && ids.map(i => i.toString()).includes(id.toString())) {
                    this.setFilter({
                        name: target,
                        value: ids.filter(idItem => idItem != id)
                    });
                }
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.catalogue-thematiques {
    @apply flex flex-col justify-center mb-4 mx-4 overflow-hidden;

    .thematiques__open-close-all {
        @apply flex items-start justify-center space-x-2;
        .btn-close-all,
        .btn-open-all {
            @apply outline-none rounded-full w-6 h-6 text-white bg-core-400 my-2;
            &:hover {
                @apply bg-core-800;
            }
        }
    }

    .thematique__label {
        @apply cursor-pointer flex flex-1 items-center justify-between rounded bg-core-400 hover:bg-core-500 text-white px-2 py-2 shadow-inner text-sm font-bold transition duration-300 ease-in-out;

        &.thematique__label--active {
            @apply bg-primary-900 hover:bg-primary-800;
        }

        .label__icon {
            @apply mx-3;
        }
        .label__text {}

        .label__clear {
            @apply mr-1 px-3 cursor-pointer rounded bg-danger-600 text-white text-xs;
            &:hover {
                @apply bg-danger-500;
            }
        }
    }

    .thematique__content {
        max-height: 9999px;
        transition: max-height 0.5s ease-in;

        &.thematique__content--close {
            max-height: 0;
            height: 100%;
            overflow: hidden;
            transition: max-height 0.25s ease-out;
        }

        .item__wrapper {
            @apply flex flex-auto;
            min-height: 3rem;

            .item {
                @apply cursor-pointer relative flex flex-1 py-1 bg-core-100 rounded items-center font-bold uppercase text-xs transition ease-out duration-100;

                .item__content {
                    @apply inline-flex flex-shrink items-center justify-center;

                    &.item_content--label {
                        @apply w-full justify-between;
                        .item__text {
                            @apply inline-flex px-2 flex-shrink leading-snug items-center justify-center;
                        }
                        .item__number {
                            @apply w-5 h-5 inline-flex items-center justify-center mx-2 p-1 rounded-full bg-core-600 text-white leading-none;
                            font-size: 0.65rem;
                        }
                    }

                    &.item_content--icon {
                        .item__icon {
                            @apply w-5 h-5 left-0 text-core-100 rounded-full transition ease-in-out p-1 mx-1 duration-200;
                            &.item__icon--cancel {
                                @apply right-0 left-auto;
                            }
                        }
                    }
                }

                &.item--empty {
                    @apply italic text-accent-400 pl-4;
                }

                &.item--active {
                    .item_content--icon .item__icon {
                        @apply text-core-700;
                    }
                }

                &.item--active:hover {
                    .item__icon {
                        @apply text-core-800;
                    }
                    .item__number {
                        @apply bg-core-800;
                    }
                }

                &.item--active .item__number {
                    @apply bg-core-800;
                }

                &:hover:not(.item--active) .item__text {
                    @apply text-core-800 transition ease-in-out duration-200;
                }

                &:hover:not(.item--active) .item__number {
                    @apply bg-core-800 transition ease-in-out duration-200;
                }
            }

        }
    }

    &.is-cncc{
        .thematiques__open-close-all {
            .btn-close-all,
            .btn-open-all {
                @apply bg-core-900;
                &:hover {
                    @apply bg-secondary-600;
                }
            }
        }

        .thematique__label {
          @apply bg-core-900 hover:bg-secondary-600 shadow-none;

          &.thematique__label--active {
            @apply bg-core-900 hover:bg-secondary-600;
          }
        }

        .thematique__content {
            @apply py-3;

            &.thematique__content--close {
                @apply py-0;
            }

            .item__wrapper {
                min-height: 2rem;

                .item {
                  @apply bg-transparent;
                    .item__content {
                        &.item_content--label {
                            .item__text {
                                @apply text-white;
                            }
                            .item__number {
                                @apply bg-white text-black;
                            }
                        }

                        &.item_content--icon {
                            .item__icon {
                                @apply text-transparent;
                            }
                        }
                    }

                    &.item--empty {
                        @apply text-white;
                    }

                    &.item--active {
                        .item_content--icon .item__icon {
                            @apply text-white;
                        }
                    }

                    &.item--active:hover {
                        .item_content--icon .item__icon {
                            @apply text-core-300;
                        }
                        .item__icon {
                            @apply text-core-300;
                        }
                        .item__number {
                            @apply bg-core-300;
                        }
                    }

                    &.item--active .item__number {
                        @apply bg-core-600;
                    }

                    &:hover:not(.item--active) .item__text {
                        @apply text-core-300;
                    }

                    &:hover:not(.item--active) .item__number {
                        @apply bg-core-300;
                    }
                }
            }
        }
    }
}
</style>
