<template>
  <div id="app"
       v-bind:class="`${is_cncc ? 'bg-primary-900' : 'bg-core-100'}`">
    <header-layout-cncc v-if="$route.meta.show_header && is_cncc"
                        ref="header"
                        class="sticky-top"/>
    <header-layout v-else-if="$route.meta.show_header"
                   ref="header"
                   class="sticky-top"/>

    <div ref="wrapper" class="layout-wrapper">

      <main class="main-content"
            v-bind:class="{'container mx-auto py-16': !$route.meta.no_container_wrapper}">
        <transition name="fade-router" mode="out-in">
          <router-view v-bind:key="$route.name"/>
        </transition>
      </main>

      <div class="fixed flex items-center justify-center right-0 bottom-0 mr-8 mb-4">
        <transition name="fade">
          <div v-if="scrolled"
               v-on:click="scrollToTop"
               class="cursor-pointer flex items-center justify-center w-12 h-12 bg-core-700 rounded-full opacity-25 hover:opacity-75 text-core-100 transition ease-in duration-200">
            <font-awesome-icon icon="chevron-up"
                               size="1x"/>
          </div>
        </transition>
      </div>

    </div>
  </div>
</template>

<script>

import {createNamespacedHelpers} from 'vuex';
const {mapMutations: mapMutationsObserver, mapState: mapStateObserver} = createNamespacedHelpers('observer');
const {mapState: mapStateAuth} = createNamespacedHelpers('auth');

import headerLayout from './components/header/header-layout.vue';
import headerLayoutCncc from './components/header/header-layout-cncc.vue';

export default {
    components: {
        headerLayout,
        headerLayoutCncc,
    },
    computed: {
        ...mapStateObserver({
            scrolled: (state) => state.scrolled,
            offset: (state) => state.offset,
        }),
        ...mapStateAuth({
            authenticated: (state) => state.authenticated
        }),
        is_cncc() {
            return process.env.VUE_APP_PRIMARY_COLOR === 'cncc';
        }
    },
    watch: {
        $route(to, from) {
            if (from.name !== null) {
                // Prevent from triggering on manual browser refresh (ex: F5)
                this.scrollToTop();
            }
            this.$nextTick(() => {
                this.updateTemplate();
            });
        }
    },
    beforeDestroy() {
        this.$refs.wrapper.removeEventListener('scroll', this.handleScrolling);
    },
    mounted() {
        this.$refs.wrapper.addEventListener('scroll', this.handleScrolling);

        // Waiting for the CSS header's height animation to end first.
        this.$nextTick(() => {
            this.updateTemplate();
        });
    },
    methods: {
        ...mapMutationsObserver({
            setScroll: 'setScroll',
            setFixedOffset: 'setFixedOffset'
        }),
        handleScrolling(evt) {
            const scrolled = this.$refs.wrapper.scrollTop >= 50;
            this.setScroll(scrolled);

            setTimeout(() => {
                // Waiting for the CSS header's height animation to end first.
                this.updateTemplate();
            }, 200);
        },
        updateTemplate() {
            const header = this.$refs.header;
            const offset = header ? header.$el.clientHeight : 0;
            const wrapper = this.$refs.wrapper;

            wrapper.style.height = `calc(100vh - ${offset}px)`;
            wrapper.style.marginTop = `${offset}px`;

            this.setFixedOffset(offset);
        },
        scrollToTop() {
            this.$refs.wrapper.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    },
};
</script>

<style lang="scss">

#app,
body,
html {
    @apply h-screen font-sans antialiased text-core-600;
    font-size: 14px;
}

#app {
    @apply flex flex-col relative;
}

.sticky-top {
    @apply fixed top-0 right-0 left-0 shadow z-50;
}

.layout-wrapper {
    @apply h-full flex flex-col flex-grow overflow-y-auto overflow-x-hidden;
}

.main-content {
    // flex-1 is mandatory to keep the footer sticking to the bottom
    @apply flex-1 w-full h-full mx-auto;
}
</style>
