import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import observer from './modules/observer';
import catalogue from './modules/catalogue';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    strict: debug,
    modules: {
        auth,
        observer,
        catalogue
    }
});
