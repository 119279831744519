const nav_menu = [
    {
        name: 'accueil',
        meta: {
            external_path: `https://${process.env.VUE_APP_HOST_WWW}`,
            label: 'Accueil',
            icon: 'home',
            size: 'lg',
        }
    },
    {
        name: 'qui-sommes-nous',
        meta: {
            external_path: `https://${process.env.VUE_APP_HOST_WWW}/qui-sommes-nous`,
            label: 'Qui sommes-nous ?',
            icon: 'person-booth',
            size: 'lg',
        }
    },
    {
        name: 'dans-vos-locaux',
        meta: {
            external_path: `https://${process.env.VUE_APP_HOST_WWW}/dans-vos-locaux`,
            label: 'Dans vos locaux',
            icon: 'users',
            size: 'lg',
        }
    },
    {
        path: '/',
        name: 'catalogue',
        meta: {
            label: 'catalogue en ligne',
            icon: 'search-plus',
            size: 'lg',
        }
    },
    {
        name: 'nous-contacter',
        meta: {
            external_path: `https://${process.env.VUE_APP_HOST_WWW}/nous-contacter`,
            label: 'Nous contacter',
            icon: 'search-location',
            size: 'lg',
        }
    }
];

export default {
    nav_menu
};
