<template>
  <nav class="sidebar">
    <transition name="slide">
      <ul v-if="show" class="sidebar__inner">
        <template v-for="item in nav_items">

          <li v-bind:key="item.name" class="sidebar__item">

            <text-button v-if="item.meta"
                         class="sidebar__link"
                         v-bind:href="item.meta.external_path ? item.meta.external_path : item.path"
                         v-bind:external_link="item.meta.external_path ? true : false"
                         v-bind:icon="item.meta.icon"
                         v-bind:size="item.meta.size"
                         v-bind:label="item.meta.label"
                         width="2.5rem"
                         height="100%"
                         label_margin="1"
                         v-bind:external_same_tab="true"/>

          </li>

          <!-- <ul v-if="item.children"
              v-bind:key="`${item.name}_children`"
              class="sidebar__subitems">

            <template v-for="subitem in item.children">
              <li v-bind:key="subitem.name"
                  class="sidebar__item sidebar__item--subitem">
                <text-button v-if="subitem.meta"
                             class="sidebar__link"
                             v-bind:href="subitem.path"
                             v-bind:icon="subitem.meta.icon"
                             v-bind:size="subitem.meta.size"
                             v-bind:label="subitem.meta.label"
                             width="2.5rem"
                             height="100%"
                             label_margin="1"/>
              </li>
            </template>
          </ul> -->

        </template>
      </ul>
    </transition>

    <div v-on:click="$emit('close')" class="sidebar__overlay"></div>

  </nav>
</template>

<script>
import navigation from '@/assets/data/data_navbar';
import textButton from '@/components/utils/text-button.vue';

import vClickOutside from 'v-click-outside';

export default {
    components: {
        textButton,
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    data() {
        return {
            show: false,
            nav_items: [],
        };
    },
    created() {
        this.loadNavMenu();
        setTimeout(()=> {
            this.show = true;
        });
    },
    methods: {
        loadNavMenu() {
            navigation.nav_menu.forEach((value) => {
                this.nav_items.push(value);
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/utils/mixins.scss';

.sidebar {
    @apply absolute top-0 flex flex-col w-full h-screen;

    .sidebar__overlay {
      @apply absolute inset-0 bg-black bg-opacity-75;
    }

    .sidebar__inner {
        @apply z-10 w-108 max-w-full h-full overflow-auto bg-core-800 shadow;
    }

    .sidebar__item {
        @apply h-16 pb-1;
    }

    .sidebar__subitems {
        @apply pl-3 pb-1;

      .sidebar__item--subitem {
          @apply pt-1;
      }
    }

    /deep/ .text-link {
        @apply justify-start;
    }
    .sidebar__link {
        @apply relative h-full flex bg-core-900 text-white items-center text-xs pl-4 cursor-pointer;
        transition: background-color ease 0.5s;
        &:hover {
            @apply bg-primary-900;
        }

        &.router-link-active,
        &.router-link-exact-active {
            @apply bg-primary-800 shadow-inner;
        }

        &:after {
            content: '';
            @apply absolute flex w-2 h-full left-0 opacity-0;
            transition: opacity ease 0.3s, width ease-in-out 0.2s;
        }
        &.router-link-active:after {
            @apply opacity-100 bg-white;
        }
        &:not(.router-link-active):hover:after {
            @apply opacity-100 bg-primary-600;
        }
    }
}
</style>
