<template>
  <div class="label-list">
    <p v-if="label" class="list__label">{{label}}</p>

    <div v-if="bullet_list">
      <ul v-if="list.length">
        <template v-for="(item, key) in list">
          <li v-if="!item.isAutre"
              v-bind:key="`item-${key}`"
              v-on:click="labelSearch(item.id)"
              class="leading-none mb-1"
              v-bind:class="{'cursor-pointer hover:underline': query_type}">
            {{item.label}}
          </li>
          <div v-else-if="item.isAutre" v-bind:key="`item-${item.id}`">
            <h5 class="leading-none mt-3 -ml-4 text-xs italic">Autres :</h5>
            <li class="leading-none mt-2">{{item.label}}</li>
          </div>
        </template>
      </ul>
      <div v-else class="text-sm text-core-500 italic">
        Aucun(e) {{label ? label.toLowerCase() : type.toLowerCase()}} disponible
      </div>
    </div>

    <div v-else
         class="list__content"
         v-bind:class="{
           'justify-center': center,
           'justify-end': right,
           'py-6': large_y_spacing,
           'px-10': large_x_spacing,
           'bg-accent-100 rounded-lg shadow-inner px-4': box
         }">
      <div v-if="list && list.length"
           class="content__tag-wrapper"
           v-bind:class="{
             'justify-center': center,
             'justify-end': right
           }">

        <span v-on:click="labelSearch(item.id)"
              v-for="(item, key) in list"
              v-bind:key="`item-${key}`"
              class="tag"
              v-bind:class="[assignCategoryColor(type, item.id), query_type && 'tag--clickable']">
          {{item.label | firstLetterCapitalize}}
        </span>

      </div>
      <div v-else-if="!hide_label" class="text-sm text-core-500 italic">
        Aucun(e) {{label ? label.toLowerCase() : type.toLowerCase()}} disponible
      </div>
    </div>

  </div>
</template>

<script>
import api_utils from '@/mixin/api_utils';
import helpers_filters from '@/mixin/helpers_filters';

import {
    map_tag_colors, map_type_colors
} from '@/assets/data/data_maps.js';

export default {
    mixins: [api_utils, helpers_filters],
    props: {
        bullet_list: {
            type: Boolean,
            default: false
        },
        hide_label: {
            type: Boolean
        },
        center: {
            type: Boolean,
            default: false
        },
        box: {
            type: Boolean,
            default: false
        },
        right: {
            type: Boolean,
            default: false
        },
        large_y_spacing: {
            type: Boolean,
            default: false
        },
        large_x_spacing: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: 'default'
        },
        query_type: {
            type: [String, Object]
        },
        list: {
            type: [Array, Object],
            default: () => {
                return [];
            }
        }
    },
    methods: {
        assignCategoryColor(name, id) {
            if (name === 'type_formation') {
                return `bg-${map_type_colors[id]} text-core-100`;
            } else {
                const colors_map = {
                    ...map_tag_colors,
                    default: 'bg-accent-600 text-core-100'
                };

                const is_match_colors_map = Object.prototype.hasOwnProperty.call(colors_map, name);

                return is_match_colors_map ? colors_map[name] : colors_map['default'];
            }
        },
        labelSearch(id) {
            if (typeof this.query_type === 'string') {
                const filter = {[this.query_type]: [id]};
                const query = this.formatParams(filter);

                this.$router.push({name:'catalogue', query});
            } else if (this.query_type) {
                const query_name = `${this.query_type[id]}`;
                const filter = {[query_name]: [true]};
                const query = this.formatParams(filter);

                this.$router.push({name:'catalogue', query});
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.label-list {
    @apply w-full;

    .list__label {
        @apply font-bold text-core-500 text-sm mb-1;
    }

    .list__content {
        @apply flex flex-nowrap;

        .content__tag-wrapper {
            @apply flex flex-wrap -mx-1;

            .tag {
                @apply inline-flex text-center text-xs rounded-full font-bold px-4 shadow-inner mt-1.5 mx-1 leading-tight;

                &.tag--clickable {
                  @apply cursor-pointer hover:shadow hover:underline;
                }
            }
        }
    }
}
</style>
