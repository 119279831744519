<template>
  <div class="home-button">
    <a v-bind:href="href">
      <font-awesome-icon v-bind:icon="icon" v-bind:size="size"/>
    </a>

  </div>
</template>

<script>
export default {
    data() {
        return {
            icon: ['fas', 'home'],
            href: `https://${process.env.VUE_APP_HOST_CATALOGUE}`,
            size: 'lg'
        };
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/utils/mixins.scss';

.home-button {
    @apply inline-flex justify-around w-20 h-full items-center z-50 hover:text-primary-900;

    .home-button__item {
        @apply w-8 h-8;

        @include round-icon();
    }
}
</style>
