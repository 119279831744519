<template>
  <nav class="navbar">

    <ul class="navbar__inner">
      <template v-for="(item, index_item) in nav_items">

        <li v-on:mouseenter="loadLinkContent(item.name)"
            v-on:mouseleave="switchHover(false)"
            v-on:click="switchHover(false)"
            v-bind:key="`key-link-${index_item}`"
            class="navbar__item">

          <text-button v-if="item.meta"
                       class="navbar__link"
                       v-bind:class="{'router-link-exact-active router-link-active': (item.name === 'catalogue')}"
                       v-bind:href="item.meta.external_path ? item.meta.external_path : item.path"
                       v-bind:external_link="item.meta.external_path ? true : false"
                       v-bind:external_same_tab="true"
                       v-bind:icon="item.meta.icon"
                       v-bind:size="'1x'"
                       v-bind:label="item.meta.label"
                       label_margin="4"/>

        </li>

      </template>
    </ul>
  </nav>
</template>

<script>
import navigation from '@/assets/data/data_navbar';
import textButton from '@/components/utils/text-button.vue';

export default {
    components: {
        textButton,
    },
    data() {
        return {
            hover: false,
            current_nav_name: null,
            current_nav_content: [],
            nav_items: [],
            navbar__content: []
        };
    },
    created() {
        this.loadNavMenu();
    },
    methods: {
        switchHover(state) {
            this.hover = state;
        },
        loadNavMenu() {
            navigation.nav_menu.forEach((value) => {
                this.nav_items.push(value);
            });
        },
        loadLinkContent(content_name) {
            this.hover = true;
            this.current_nav_name = content_name;
            this.current_nav_content = this.navbar__content[content_name];
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/utils/mixins.scss';

.navbar {
    @apply flex flex-wrap w-full h-full;

    .navbar__inner {
        @apply flex w-full h-full relative mx-auto;
    }

    .navbar__item {
        // h-* important to retrive here h-* from class .header__inner--end in parent component (header-layout.vue)
        @apply relative flex items-center flex-1 h-12;
    }

    .navbar__link {
        @apply h-full flex text-center bg-core-900 text-white items-center justify-center text-sm p-3;
        transition: background-color ease 0.5s;

        &.router-link-active,
        &.router-link-exact-active
        {
            @apply bg-secondary-600 shadow-inner;

        }

        &:after {
            content: '';
            @apply absolute flex w-0 h-1 mx-auto bottom-0 left-0 right-0 opacity-0;
            transition: opacity ease 0.3s, width ease-in-out 0.2s;
        }
        &:not(.router-link-active):hover:after {
            @apply w-full opacity-100 bg-secondary-600;
        }
    }
}
</style>
