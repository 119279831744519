<template>
  <div class="flex justify-center items-center">
    <form method="post"
          class="pt-6 px-6 flex flex-col">

      <div class="flex items-center justify-between">
        <a v-bind:href="connectWithOidcProvider"
           v-on:click="triggerRedirectPath"
           class="w-full flex items-center rounded shadow hover:shadow-md uppercase bg-white text-core-900 hover:text-primary-900 font-bold py-5 px-8 focus:outline-none transition duration-150 ease-in-out">
          <span class="inline-flex ml-3">Me connecter à </span>
          <img v-bind:src="fetchLogoJinius"
               alt="Jinius"
               class="inline-flex w-14 mx-2"/>
        </a>
      </div>

      <div class="flex items-center justify-center mt-4">
        <a href="#"
           class="inline-block normal-case text-sm hover:text-primary-900 hover:underline"
           v-tooltip="is_navbar ? {
             placement: 'bottom',
             offset: '30',
             /* show:true, */
             /* autoHide:false, */
             content: safirInfo()
           } : null">
          <span class="mx-2">Vous n'avez pas de compte Jinius ?</span>
          <font-awesome-icon v-if="is_navbar"
                             size="lg"
                             v-bind:icon="['fas', 'info-circle']"/>
        </a>
      </div>
    </form>

    <div v-if="!is_navbar"
         v-html="safirInfo()"
         class="flex flex-col text-left ml-2 pl-6 border-core-400 border-l-1">
    </div>

  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex';
const {mapState: mapStateAuth, mapMutations: mapMutationsAuth} = createNamespacedHelpers('auth');

export default {
    props: {
        is_navbar: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapStateAuth({
            authenticated: (state) => state.authenticated
        }),
        connectWithOidcProvider() {
            return `${process.env.VUE_APP_API_AUTH}/login?to=${process.env.VUE_APP_AUTH_REDIRECT}`;
        },
        fetchLogoJinius() {
            return 'https://cdn.jinius.fr/logos/jinius.svg';
        }
    },
    methods: {
        ...mapMutationsAuth({
            setRedirectUrlPath: 'setRedirectUrlPath'
        }),
        triggerRedirectPath() {
            if (!this.$route.meta.requiresAuth && this.$route.name !== 'connexion') {
                this.setRedirectUrlPath(this.$route.fullPath);
            }
        },
        safirInfo() {
            return `<section class='w-84 py-4 px-2 mb-0'>
          <h6 class='m-0 mb-4'>Jinius Connect qu'est-ce que c'est ?</h6>
          <p class='m-0 text-sm text-justify'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias reprehenderit aliquid, tempore. Ipsam dignissimos, magni deleniti, quia, architecto maiores odio numquam delectus officia aspernatur quo totam harum incidunt ea necessitatibus enim ratione. Incidunt explicabo optio illo consequuntur earum perferendis quis excepturi sint, nam unde quaerat iste sequi rerum, quas? Ducimus.</p>
          </section>`;
        },
    }
};
</script>

<style lang="scss" scoped>
</style>
