<template>
  <label>
    <font-awesome-icon v-if="icon"
                       v-bind:icon="icon"
                       v-bind:size="icon_size"/>
    <span v-bind:class="{'label-offset': icon}">
      {{label}}
    </span>
  </label>
</template>
<script>
export default {
    props: {
        label: String,
        icon: [String, Array],
        icon_size: String
    }
};
</script>
<style lang="scss" scoped>
.label-offset {
  @apply ml-2;
}
</style>
