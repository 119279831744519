<template>
  <div class="checkbox-container">
    <input type="checkbox"
           v-on:change="$emit('input', $event.target.checked)"
           v-bind:checked="value"/>
    <span></span>
  </div>
</template>
<script>
export default {
    props: ['value']
};
</script>
<style lang="scss" scoped>
/* CUSTOM "CHECKED" ICON */
.checkbox-container input:checked ~ span {
    &:before {
        content: "\2713";
        @apply bg-primary-900 text-white;
    }
}
.checkbox-container input {
    display: none;
}

/* CUSTOM SQUARE */
.checkbox-container span:before {
    @apply inline-flex items-center justify-center w-5 h-5 mr-1 bg-core-200 shadow-inner rounded;
    content: "\00a0";
    /* Blank space */
}

/* OPTIONAL COSMETICS */
.checkbox-container span {
    @apply cursor-pointer leading-none;
}
.checkbox-container span:hover:before {
    @apply bg-core-400;
}
</style>
