import Vue from 'vue';
import VueHtmlToPaper from 'vue-html-to-paper';

const options = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=no',
        'scrollbars=yes'
    ],
    styles: [
        `https://${process.env.VUE_APP_HOST_CATALOGUE}/print.css`
    ]
};

Vue.use(VueHtmlToPaper, options);

